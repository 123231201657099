
import { Dispatch } from 'redux'
import * as actionCreateLink from '../actions'
import * as actionUser from '../../user/actions'
import { CreateLinkActions } from '../types'
import { TokenActions } from '../../token/types'
import { UserActions } from '../../user/types'
import { ethers } from 'ethers'
import { RootState, IAppDispatch } from 'data/store'
import { alertError } from 'helpers'
import getERC20TokenData from '../../token/async-actions/get-erc20-token-data'
import { nativeTokenAddress } from 'configs/application'
import getNativeTokenData from '../../token/async-actions/get-native-token-data'
import { plausibleApi } from 'data/api'

export default function setTokenAmount(
  amount: string,
  approveCallback: () => void,
  depositCallback: () => void
) {
  return async (
    dispatch: Dispatch<CreateLinkActions> &
              Dispatch<TokenActions> &
              Dispatch<UserActions> &
              IAppDispatch,
    getState: () => RootState
  ) => {
    const {
      token: {
        decimals,
        tokenType,
        address: tokenAddress
      },
      user: {
        chainId,
        sdk,
        address,
        provider
      }
    } = getState()
    dispatch(actionCreateLink.setLoading(true))
    try {

      if (!sdk) {
        return alertError('SDK is not found')
      }
  
      if (!tokenAddress) {
        return alertError('tokenAddress is not defined')
      }

      const amountAtomic = ethers.utils.parseUnits(amount, decimals)

      const claimLink = await sdk.createClaimLink({
        chainId: Number(chainId),
        from: address,
        tokenType,
        token: tokenAddress,
        amount: amountAtomic.toString()
      })

      console.log({
        claimLink
      })

      dispatch(actionCreateLink.setAmount(amountAtomic))
      dispatch(actionCreateLink.setHumanReadableAmount(amount))
      if (claimLink) {

        plausibleApi.invokeEvent({
          eventName: 'claim_link_created',
          data: {
            tokenAddress: String(tokenAddress),
            chainId: String(chainId),
            transferId: claimLink.transferId
          }
        })

        dispatch(actionUser.setClaimLink(claimLink))

        const feeAmount = claimLink.feeAmount
        const feeToken = claimLink.feeToken
        dispatch(actionCreateLink.setFeeAmount(feeAmount))
        dispatch(actionCreateLink.setFeeToken(feeToken))

        let feeTokenData

        if (feeToken === nativeTokenAddress) {
          feeTokenData = getNativeTokenData(chainId)
        } else {
          feeTokenData = await getERC20TokenData(
            provider,
            feeToken,
            chainId
          )
        }
        const feeAmountHumanReadable = ethers.utils.formatUnits(feeAmount, feeTokenData.decimals) 
        dispatch(actionCreateLink.setHeumanReadableFeeAmount(feeAmountHumanReadable))
        dispatch(actionCreateLink.setFeeTokenSymbol(feeTokenData.symbol))

        if (claimLink.escrowAddress) {
          dispatch(actionCreateLink.setEscrowAddress(claimLink.escrowAddress))
        }

        const isDepositWithAuthAvailable = claimLink.isDepositWithAuthorizationAvailable(tokenAddress)
        const isNative = tokenAddress === nativeTokenAddress
        dispatch(actionCreateLink.setIsDepositWithAuthAvailable(!isNative && isDepositWithAuthAvailable))
        // depositWithAuth => stablecoins
        // deposit => native tokens + erc20

        if (isDepositWithAuthAvailable || isNative) {
          depositCallback()
        } else {
          approveCallback()
        }
      }
    } catch (err) {
      alertError('Some error occured. Please check console for more info')
      console.error(err)
    }
    dispatch(actionCreateLink.setLoading(false))

  }
}
