import { Dispatch } from 'redux'
import {
  CreateLinkActions,
} from '../types'
import { alertError, defineNetworkName } from 'helpers'
import { RootState } from 'data/store'
import { TCustomSigner } from 'types'
import * as actionsCreateLink from '../actions'
import { plausibleApi } from 'data/api'
import randomBytes from 'randombytes'

type TDomain = {
  name: string
  version: string
  verifyingContract: string
  salt?: string
  chainId?: number
}

const recoverLink = (
  callback?: (linkId: string) => void
) => {
  return async (
    dispatch: Dispatch<CreateLinkActions>,
    getState: () => RootState
  ) => {

    const {
      user: {
        chainId,
        sdk
      },
      user: {
        claimLink,
        signer
      }
    } = getState()
    dispatch(actionsCreateLink.setLoading(true))
    try {

      const link = await claimLink?.generateClaimUrl({
        signTypedData: (
          domain: TDomain,
          types: any,
          message: Record<string, string | number>
        ) => (signer as TCustomSigner)._signTypedData(domain, types, message),
        getRandomBytes: (length: number) => new Uint8Array(randomBytes(length)) 
      })
      if (!link) {
        throw new Error('Link was not generated')
      }

      plausibleApi.invokeEvent({
        eventName: 'claim_link_recovered',
        data: {
          network: defineNetworkName(chainId)
        }
      })
      
      callback && callback(link.transferId)
    } catch (e) {
      const err = e as { code: string }
      if (err.code === "ACTION_REJECTED") {
        alertError('You cancelled the link creation. Please try again')
      } else {
        alertError('Some error occured, please check console for more information')
      }
      console.log({ err })
    }
    dispatch(actionsCreateLink.setLoading(false))
  }
}

export default recoverLink

